import { Footer, Header, Main } from '../structure'
import { FaExclamationCircle } from 'react-icons/fa'

export function NotFoundPage() {
  return (
    <>
      <Header />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>No se ha encontrado la pagina</span>
        <FaExclamationCircle className='text-[3rem]' />
      </Main>
      <Footer />
    </>
  )
}