import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HomePage, NotFoundPage, AboutUsPage, TranscodificadorPage, SMCPage, InfoboxPage, CableboxPage, HeadboardPage, ContactUsPage, TechnicalAssistancePage, SuscriberTerminalsPage, APPPage, ApiPage, ProvisioningPage, DemoPage, LegalWarningPage, IpguiaPage, DemoAdminPage } from './pages'
import { Routes, Route } from 'react-router-dom'
import { FacebookRedirect, InstagramRedirect, TwitterRedirect, AndroidAppRedirect, LGAppRedirect } from './pages/Redirect'

export default function App() {
  const location = useLocation();
  const [cookies, setCookies] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("cookies")) {
      setCookies(true);
      document.body.classList.remove("no-scroll");
    }else{
      document.body.classList.add("no-scroll");
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);
  const handleClick = () => {
    localStorage.setItem("cookies", true);
    setCookies(true);
    document.body.classList.remove("no-scroll");
  }
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/Transcodificador' element={<TranscodificadorPage />} />
        <Route path='/SMC' element={<SMCPage />} />
        <Route path='/APP' element={<APPPage />} />
        <Route path='/Suscriptores' element={<SuscriberTerminalsPage />} />
        <Route path='/Cabecera' element={<HeadboardPage />} />
        <Route path='/IpGUIA' element={<IpguiaPage />} />
        <Route path='/InfoBox' element={<InfoboxPage />} />
        <Route path='/CableBox' element={<CableboxPage />} />
        <Route path='/API' element={<ApiPage />} />
        <Route path='/Aprovisionamiento' element={<ProvisioningPage />} />
        <Route path='/SobreIoPLAY' element={<AboutUsPage />} />
        <Route path='/SolicitarDemo' element={<DemoPage />} />
        <Route path='/DemoAdmin' element={<DemoAdminPage />} />
        <Route path='/AvisoLegal' element={<LegalWarningPage />} />
        <Route path='/Contacto' element={<ContactUsPage />} />
        <Route path='/AsistenciaTecnica' element={<TechnicalAssistancePage />} />
        <Route path='/AndroidApp' element={<AndroidAppRedirect />} />
        <Route path='/LGApp' element={<LGAppRedirect />} />
        <Route path='/Facebook' element={<FacebookRedirect />} />
        <Route path='/Twitter' element={<TwitterRedirect />} />
        <Route path='/Instagram' element={<InstagramRedirect />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      {
        !cookies ? <div className='w-full h-full fixed top-0 left-0 flex justify-center items-end z-[100] bg-[#00000020] p-[25px]'>
          <div className='w-full min-h-[100px] bg-[#fff] rounded-[16px] p-[15px] flex justify-between items-start sm:flex-col gap-[10px] sm:items-center'>
            <div>
              <span className='text-[1.5rem] font-bold'>Cookies</span>
              <p className='text-[1.1rem]'>Este sitio web utiliza cookies y seguimiento de terceros para garantizar que obtenga la mejor experiencia y mejore continuamente nuestros servicios.</p>
            </div>
            <button onClick={handleClick} className='my-auto bg-[#0794d9] text-[#fff] text-[1.2rem] rounded-[10px] min-w-[190px] px-[25px] py-[10px]'>Aceptar cookies</button>
          </div>
        </div> : null
      }
    </>
  )
}