import { Link } from 'react-router-dom'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { useSpring, animated } from 'react-spring'

export default function Accordion(props) {
  return (
    <li className={(props.items.includes(props.active) ? "text-[#0794d9] " : "") + "relative text-[1.6rem] bg-[#2f353c]"} onClick={props.onClick}>
      <span className='cursor-default flex justify-between items-center'>{props.children} {props.expanded ? <FaAngleUp /> : <FaAngleDown />}</span>
      <AccordionMenu expanded={props.expanded}>
        {
          props.items.map((element, index) => {
            return <li key={index} className={(props.active === element ? "text-[#0794d9] " : "text-[#fff] ") + "text-left pl-[15px]"}><Link to={props.itemsLinkTo[index]}>{element}</Link></li>
          })
        }
      </AccordionMenu>
    </li>
  )
}

function AccordionMenu(props) {
  const style = useSpring({
    maxHeight: props.expanded ? '100%' : '0px',
    config: { duration: 150 }
  });
  return (
    <animated.ul className="flex relative flex-col text-[1.28rem] overflow-hidden" style={style}>
      {props.children}
    </animated.ul>
  )
}