import { Footer, Main, Header } from '../structure'
import { FaCogs, FaMoneyBill, FaServer, FaTools, FaCode, FaLaptop } from 'react-icons/fa'
import { BiSupport } from "react-icons/bi";
import { MdAppSettingsAlt } from "react-icons/md";

export function ContactUsPage() {
  return (
    <>
      <Header />
      <Main className="py-[40px!important] bg-1 bg-no-repeat bg-cover">
        <div className='grid grid-cols-2 sm:grid-cols-1 lg:px-[80px] md:px-[10px] sm:gap-[25px] md:gap-[30px] lg:gap-[50px] justify-items-center'>
          <div className='contactCard'>
            <FaCogs className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>DIRECTOR OPERACIONES</span>
              <span>JOHN VILLARREAL</span>
              <span>+57-3214995470</span>
              <span>john.villarreal@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <FaMoneyBill className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>DIRECCIÓN FINANCIERA</span>
              <span>KAREN GUARIN</span>
              <span>+57-3105676808</span>
              <span>karen.guarin@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <FaServer className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>DIRECTOR INFORMATICA</span>
              <span>OSCAR DUQUE</span>
              <span>+57-3185929300</span>
              <span>oscar.duque@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <FaTools className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>SOPORTE Y GESTION: RED EXTERNA</span>
              <span>DEIVY ALVAREZ</span>
              <span>+57-3143567760</span>
              <span>deivy.alvarez@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <FaLaptop className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>SOPORTE Y GESTION: APLICATIVOS</span>
              <span>DANIEL VILLARREAL</span>
              <span>+57-3118525961</span>
              <span>daniel.villarreal@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <FaCode className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>SOPORTE Y GESTION: APLICACIONES</span>
              <span>KEVIN GUARIN</span>
              <span>+57-3102289656</span>
              <span>kevin.guarin@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <BiSupport className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>SOPORTE</span>
              <span>soporte@ioplay.co</span>
            </div>
          </div>
          <div className='contactCard'>
            <MdAppSettingsAlt className="text-[3rem] md:text-[4rem] text-[#0794d9] md:mx-auto" />
            <div className='flex flex-col'>
              <span className='font-bold'>APLICACIONES</span>
              <span>app@ioplay.co</span>
            </div>
          </div>
        </div>
      </Main>
      <Footer page="Contacto" />
    </>
  )
}