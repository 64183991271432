import { Footer, Main, Header } from '../../structure'

export function IpguiaPage() {
  return (
    <>
      <Header page="IpGUIA" />
      <Main className="min-h-[calc(100vh-370px)] bg-1 bg-no-repeat bg-cover py-[0!important] flex justify-end flex-col">
        <div className='w-full h-auto sm:px-[10px] md:px-[50px] lg:px-[150px] py-[92px] relative bg-[#f3f3f3] md:rounded-tl-[200px]'>
          <div className='w-[50%] sm:w-full'>
            <span className='text-[2.6rem] z-[1] relative font-bold text-[#0794d9]'>IpGUIA</span>
            <p className='flg text-[1.2rem] text-justify z-[1] relative'><span>IpGUIA</span>, es el servicio de visualización de contenido programático, en la transmisión de contenido diario del cable operador. Este servicio está disponible en las plataformas <span>Transcodificador</span> <span>SMC</span>, mediante la inserción de contenido en un programa de emisión propio ininterrumpido, y de difusión total a los suscriptores.</p>
          </div>
          <div className='bg-IpGUIA bg-center w-full h-full absolute top-0 left-0 opacity-[.8] z-0 sm:opacity-[.4] bg-no-repeat'></div>
        </div>
      </Main>
      <Footer />
    </>
  )
}