import { Footer, Main, Header } from '../../structure'
import { FaWrench } from 'react-icons/fa'

export function ApiPage() {
  return (
    <>
      <Header page="API" />
      <Main className="flex justify-center items-center flex-col text-[2.75rem] text-[#0794d9] min-h-[calc(100vh-370px)] py-[100px!important] bg-1 bg-no-repeat bg-cover">
        <span>En mantenimiento</span>
        <FaWrench className='text-[3rem]' />
      </Main>
      <Footer />
    </>
  )
}