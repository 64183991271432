export function AndroidAppRedirect() {
  window.location.href = "https://play.google.com/store/apps/details?id=com.stream.ioplaygo";
}
export function LGAppRedirect() {
  window.location.href = "https://es.lgappstv.com/main/tvapp/detail?appId=1219283";
}
export function FacebookRedirect() {
  window.location.href = "https://www.facebook.com/IoPLAYGO";
}
export function TwitterRedirect() {
  window.location.href = "https://twitter.com/ioplaygo";
}
export function InstagramRedirect() {
  window.location.href = "https://www.instagram.com/ioplaygo/";
}