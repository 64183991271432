import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

export default function Dropdown(props) {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = (e) => {
    setShow(false);
  }
  return (
    <li onMouseEnter={showDropdown} onMouseLeave={hideDropdown} className={(props.items.includes(props.active) ? "active " : "") + "itemHeader"}>
      <span className='cursor-default'>{props.children}</span>
      <DropdownMenu show={show} active={props.active}>
        {
          props.items.map((element, index) => {
            return <li key={index} className={(props.active === element ? "text-[#0794d9] " : "text-[#333] ") + "hover:text-[#0794d9]"}><Link to={props.itemsLinkTo[index]}>{element}</Link></li>
          })
        }
      </DropdownMenu>
    </li>
  )
}

function DropdownMenu(props) {
  const style = useSpring({
    '--tw-scale-y': props.show ? '1' : '0',
    opacity: props.show ? '1' : '0'
  }
  );
  return (
    <animated.ul className="flex absolute bg-white shadow-[1px_1px_5px_0_#ccc] p-[15px] min-w-[175px] flex-col left-[50%] translate-x-[-50%] origin-top z-10 top-[100%]" style={style}>
      {props.children}
    </animated.ul>
  )
}